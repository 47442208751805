<template>
	<div class="container">

		<!-- If the user has app access -->
		<template v-if="hasAppAccess">
			<div class="row">
				<div class="col-md-12 mb-2">
					<!-- Company dashboard -->
					<repair-search></repair-search>
				</div>
			</div>
		</template>

		<!-- If the user doesn't have app access -->
		<empty-state
			v-if="!hasAppAccess"
			statusIcon="error"
			title="You are not authorised to use this app">
		</empty-state>

	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {

	computed:{
		...mapGetters([
			"hasAppAccess",
			"isSuperAdmin",
			"isStore",
			"user"
		]),
	},

	methods: {
		...mapActions([
			"displayToast",
			"refreshUser"
		]),

	}
}
</script>

<style scoped>

</style>